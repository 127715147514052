import React from 'react'
import { Link } from 'gatsby'
import { AppSlider, Mandala, BlogItem } from '@common'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const BlogSection = ({
	title = 'Na naszym blogu',
	posts = [],
	buttonText = 'Przejdź do bloga',
	button = true,
	slider = false,
}) => {
	return (
		<React.Fragment>
			<div className='blog'>
				<div className='container'>
					{title.length !== 0 && <div className='row justify-content-center'>
						<h1>{title}</h1>
					</div>}
					{slider ? (
						<div className='blog-slider'>
							<AppSlider items={posts} SliderItem={BlogItem} />
						</div>
					) : (
						<div className='blog-list'>
							{posts.map((post, key) => (
								<BlogItem {...post} key={key} />
							))}
						</div>
					)}
				</div>
			</div>

			{button && (
				<Mandala height={250}>
					<div className='container'>
						<div className='row justify-content-center'>
							<Link
								className='button'
								to={'/blog/'}
								onClick={() => {
									trackCustomEvent({
										category:
											'Blog Button - Poczytaj o CBD',
										action: 'Click',
										label: 'Poczytaj o CBD',
									})
								}}
							>
								{buttonText}
							</Link>
						</div>
					</div>
				</Mandala>
			)}
		</React.Fragment>
	)
}
export default BlogSection
