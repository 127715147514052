import React from 'react'
import {NewsletterForm} from '@common'

export const NewsletterSection = ({button, url}) => {
    return (
        <div className="newsletter">
            <div className="container">
                <div className="row justify-content-center">
                    <NewsletterForm />
                </div>
            </div>
        </div>
    )
}

export default NewsletterSection