import React from 'react'
import { Layout } from '@common'
import { NewsletterSection, AttentionSection, 
	CategoriesSection, ProductsSection } from '@sections'
import './styles/shop.scss'

const Shop = ({ pageContext }) => {
	return (
		<Layout
			seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}
		>
			<AttentionSection  title={"Sklep - sprawdź naszą ofertę"}/>
			<ProductsSection recommended={true} products={pageContext.productsWithDetails} maxCount={12}
			button={false}
			/>
			<CategoriesSection categories={pageContext.productCategories} slider={false} />
			<NewsletterSection />
		</Layout>
	)
}

export default Shop
