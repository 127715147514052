import React from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import {Parallax} from '@common'
import {ResizedImage} from '@common'

import './styles/slider.scss'

const settings = {
  infinite: true,
  arrows: false,
  slidesToShow: 4,
  dotsClass: "slick-dots slick-thumb",
  slidesToScroll: 1,
  autoplay: true,
  speed: 400,
  autoplaySpeed: 5000,
  cssEase: "ease-in-out",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const AttentionSlide =({srcSet, sourceUrl}) => {
  return (
    <div className="attention-slide"> 
    <ResizedImage srcSet={srcSet} 
    size={"300w"}
    sourceUrl={sourceUrl}
    />
    </div>
  )
}

const AttentionSlider=({ images }) =>{
  return (
    <div className="attention-slider">
    <Slider 
      {...settings}>
        {images.map((el, k) => {
          let element = el.slide
          return(
            <div key={k}>
              <AttentionSlide {...element} />
            </div>
          )
        })}
    </Slider>
    </div>
  )
}

const AttentionSection = ({ height, image}) => {
    const {WP} = useStaticQuery(graphql`
    query{
      WP {
        pageBy(uri: "home") {
          ACF_Images {
            images {
              images {
                slide {
                  sourceUrl
                  srcSet
                }
              }
            }
          }
        }
      }
    }
    `)

    const {images} = WP.pageBy.ACF_Images.images

    return (
        <section className="attention" >
          <AttentionSlider images={images} />
        </section>
    )
}

export default AttentionSection
