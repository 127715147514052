import React from 'react'
import {Link} from 'gatsby'
import {ProductItem, AppSlider} from '@common'

const ProductsSection = ({title="Polecane produkty", products, recommended=false, maxCount=12, button=true, slider=false }) => {
    let _products = recommended ? products.filter((el)=>el.featured) : products
    _products = _products.filter((el,k) => k < maxCount)

    return (
        <section className="products">
            <div className="container">
                <div className="row justify-content-center">
                    <h1>{title}</h1>
                </div>
                {slider ? (
					<div className='products-slider'>
						<AppSlider
							items={_products}
							SliderItem={ProductItem}
						/>
					</div>
				) : (
					<div className='products-list'>
						{_products.map((product, key) => (
							<ProductItem {...product} key={key} />
						))}
					</div>
				)}

                {button &&  <div className="row justify-content-center">
                <Link to="/shop/" className="products-more">Zobacz więcej</Link>
            </div>}
               
            </div>
        </section>
    )
}
export default ProductsSection