import React from 'react'
import { AppSlider, CategoryItem } from '@common'

const CategoriesSection = ({
	title = 'Kategorie',
	categories = [],
	slider = false,
	maxCount = 12
}) => {
	let categoriesWithElements = categories.filter((el) => el.count)
	categoriesWithElements = categoriesWithElements.filter(
		(el, k) => k < maxCount
	)

	return (
		<section className='categories'>
			<div className='container'>
				<div className='row justify-content-center'>
					<h1>{title}</h1>
				</div>

				{slider ? (
					<div className='categories-slider'>
						<AppSlider
							items={categoriesWithElements}
							SliderItem={CategoryItem}
						/>
					</div>
				) : (
					<div className='categories-list'>
						{categoriesWithElements.map((category, key) => (
							<CategoryItem {...category} key={key} />
						))}
					</div>
				)}
			</div>
		</section>
	)
}

export default CategoriesSection
